<template>
    <div class="login-page">
      <div class="login-form">
        <img src="../assets/cloud9logo.png" class="login-logo" alt="Animeflix Logo">
        <h2>Login</h2>
        <form @submit.prevent="login">
          <div class="form-group">
            <v-text-field v-model="username" label="Username or Email" required rounded></v-text-field>
          </div>
          <div class="form-group">
            <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    methods: {
      login() {
        // Implement your login logic here
        // Example: send login request to backend API
        console.log('Username:', this.username);
        console.log('Password:', this.password);
      }
    }
  }
  </script>
  
  <style scoped>
  .login-page {
    background: url('../assets/movegif.gif') center/cover no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-form {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    width: 500px; /* Adjust the width */
    height: 500px;
  }
  
  .login-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  