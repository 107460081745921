<template>
  
  <div>
    <v-app-bar app color="black" >
  <v-toolbar-title class="mx-auto">
    <img src="../assets/cloud9logo.png" class="pa-3" height="130px" alt="Animeflix Logo">
  </v-toolbar-title>
  <v-tabs dark>
    <v-tab @click="redirectToAllMovies">
      <v-icon>mdi-movie</v-icon>
      Movies
    </v-tab>
    <v-tab>
      <v-icon>mdi-television</v-icon>
      Series
    </v-tab>
    <v-tab @click="navigateToLogin">
      <v-icon>mdi-login</v-icon>
      Login
    </v-tab>
  </v-tabs>
  <v-spacer>
    
  </v-spacer>
  <v-text-field
  v-model="searchQuery"
  label="Search movies"
  single-line
style="width:500px"
  dark
  solo dense class="mx-2"
  hide-details
  @keyup.enter="searchMovies"
></v-text-field>
<v-navigation-drawer v-model="drawer" app>
      <!-- Content for the drawer (e.g., links, menus) -->
      
      <v-list>
        <v-list-item link v-for="item in menuItems" :key="item.title" @click="redirectPage(item.link)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
<v-icon color="white" @click="searchMovies">mdi-magnify</v-icon>

  <v-btn icon @click="redirectToPage">
    <v-icon color="white">mdi-laptop</v-icon>
  </v-btn>
  
</v-app-bar>
<v-row class="scrolling-wrapper">
  <v-col
    v-for="result in searchResults"
    :key="result.imdb_id"
    cols="4" sm="6" md="2">
    <v-card class="my-2" style="width: 110px; background-color: white; border-radius: 20px; overflow: hidden;" @click="showMovieDialog(result.imdb_id)">
      <v-img :src="result.img" height="150px" width="110px"></v-img>
    </v-card>
  </v-col>
</v-row>
<v-carousel  hide-delimiters height="650" width="100%" cycle>
      <v-carousel-item
        v-for="(movie, index) in movies"
        :key="movie.id"
        :src="getImageUrl(movie.backdrop_path)"
        reverse-transition="fade-transition"
        @mouseenter="hoverMovie(movie.trailerUrl)"
  @mouseleave="unhoverMovie"
        @click="showMoviePlayer(movie.imdb_id)"
      >
        <v-sheet
          style="background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0) 100%);"
          tile
          height="100%"
          width="100%"
          class="d-flex"
          align-end
        >
        <v-img :src="getImageUrl(movie.backdrop_path)" height="100%" style="position: relative;">
          <iframe 
  :src="hoveredUrl" 
  frameborder="0" 
  allow="autoplay; fullscreen" 
  style="width: 100%; height: 100%; position: absolute; top: 0; left: 0;"
></iframe>
  </v-img>
          <v-container style="border-radius: 20px;" class="fill-height" fluid>
            <v-row no-gutters align="center" justify="start" >
              <v-col cols="12">
                <div class="overlay-text pa-5">
                  <span style="color:#f44336" class="movie-title">{{ movie.title }}</span>
                  <p class="movie-description white--text">{{ movie.overview }}</p>
                  <!-- Display additional movie details -->
                  <p class="movie-info white--text">
                    <v-icon color="yellow">mdi-star</v-icon> Rating: {{ movie.vote_average }}
                  </p>
                  <!-- End of additional movie details -->
                  <v-btn color="black" class=" mb-12" @click="playMovie(movie)"  dark>Play</v-btn>
                  <v-btn text class=" mb-12 white--text" @click="showDetails(movie)">More</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
   

<!-- <h4 class="white--text mx-3 my-4">Continue Watching</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(item, index) in continueWatching" :key="index" cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img @click="showMoviePlayer(item.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;" :src="getImageUrl(item.poster_path)"></v-img>
      <div style="padding: 10px;">
        <v-progress-linear :value="item.progress"></v-progress-linear>
      </div>
    </div>
  </v-col>
</v-row> -->

    <h4 class="white--text mx-3 my-4"> Discover Movies</h4>
    <v-row
  class="scrolling-wrapper my-4"
  no-gutters
  align="center"
  justify="start">
  <v-col
    v-for="(movie, index) in randomMovies"
    :key="index"
    cols="auto"> <!-- Use "auto" to let the column width be as wide as the content -->
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;"> <!-- Set a specific width for the card and margin for spacing -->
      <v-img
        @click="playMovie(movie.imdb_id)"
        style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;"
        :src="getImageUrl(movie.poster_path)">
      </v-img>
      <!-- The rest of the card content here -->
    </div>
  </v-col>
</v-row>
<h4 class="white--text mx-3 my-4">Popular</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(movie, index) in popularMovies" :key="index" cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
      <!-- Additional content can be added here if needed -->
    </div>
  </v-col>
</v-row>


<h4 class="white--text mx-3 my-4">Recent Movies</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(movie, index) in recentMovies" :key="index" cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
      <!-- Additional content can be added here if needed -->
    </div>
  </v-col>
</v-row>
<h4 class="white--text mx-3 my-4">Comedy Movies</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(movie, index) in comedyMovies" :key="index" cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
      <!-- Additional content can be added here if needed -->
    </div>
  </v-col>
</v-row>
<h4 class="white--text mx-3 my-4">Thriller Movies</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col v-for="(movie, index) in thrillerMovies" :key="index" cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img @click="playMovie(movie.imdb_id)" style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;" :src="getImageUrl(movie.poster_path)"></v-img>
      <!-- Additional content can be added here if needed -->
    </div>
  </v-col>
</v-row>

<!-- <h4 class="white--text mx-3 my-4">TV Shows</h4>
<v-row class="scrolling-wrapper my-4" no-gutters align="center" justify="start">
  <v-col
    v-for="(show, index) in tvShows"
    :key="index"
    cols="auto">
    <div style="width: 200px; margin: 0 10px; background-color: white; border-radius: 20px; overflow: hidden;">
      <v-img
        @click="playMovie(show.imdb_id)"
        style="border-radius: 20px 20px 0 0; width: 100%; height: 300px; object-fit: cover;"
        :src="getImageUrl(show.backdrop_path)">
      </v-img>
 
    </div>
  </v-col>
</v-row> -->

    
    <!-- Movie player dialog -->
    <v-dialog v-model="dialog" max-width="800px">
      <div style="background-color:#010028;border-radius:20px;">
        <v-card-text>
          <iframe style="border-radius:20px" :src="moviePlayerUrl" width="100%" height="550" frameborder="0" allowfullscreen></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
   
        </v-card-actions>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data: () => ({
    popularMovies: [],
    movies: [],
    dialog: false,
    comedyMovies: [],
    thrillerMovies: [],
    searchQuery: '',
  searchResults: [],
  dialog: false,
  hoveredUrl: null,
  moviePlayerUrl: '',
    randomMovies: [],
    tvShows: [], // New array for TV shows
    recentMovies: [],
    marvelMovies: [], // Add this line to store Marvel movies
    moviePlayerUrl: '',
    search: '',
      tvShows: [], // Array for TV shows
      continueWatching: [], // This will hold the continue watching data
  searchResults: [],
  drawer: false,  // Controls the state of the navigation drawer
    menuItems: [    // Menu items for the navigation drawer
      { title: 'Home', icon: 'mdi-home', link: '/' },
      { title: 'Profile', icon: 'mdi-account', link: '/profile' },
      { title: 'Settings', icon: 'mdi-settings', link: '/settings' },
      { title: 'Logout', icon: 'mdi-exit-to-app', link: '/logout' },
    ],
  }),
  created() {
  this.fetchMovies();
  this.fetchComedyMovies();
  this.fetchRandomMovies();
  this.fetchTVShows();
  this.fetchThrillerMovies();
  this.loadContinueWatching();
this.fetchMarvelMovies();
  this.fetchRecentMovies(); // Add this line
  this.fetchPopularMovies();
},

  methods: {
    hoverMovie(trailerUrl) {
    this.hoveredUrl = trailerUrl;
  },
  unhoverMovie() {
    this.hoveredUrl = null;
  },
    fetchMarvelMovies() {
  axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=2254a7974190a9cae8d203fdeb00fd0b&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=1&with_companies=420`) // 420 is the ID for Marvel Studios
    .then(response => {
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=2254a7974190a9cae8d203fdeb00fd0b`);
      });

      Promise.all(moviePromises).then(externalIdsResponses => {
        this.marvelMovies = response.data.results.map((movie, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id;
          return { ...movie, imdb_id: imdbId };
        });
        console.log('Marvel Movies:', this.marvelMovies);
      });
    })
    .catch(error => {
      console.error('Error fetching Marvel movies:', error);
    });
},
fetchPopularMovies() {
  axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=2254a7974190a9cae8d203fdeb00fd0b&language=en-US&page=1`)
    .then(response => {
      // Fetching IMDb ID for each movie
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=2254a7974190a9cae8d203fdeb00fd0b`);
      });

      Promise.all(moviePromises).then(externalIdsResponses => {
        this.popularMovies = response.data.results.map((movie, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id;
          // Assign IMDb ID and some default properties for the movie
          return { ...movie, imdb_id: imdbId, category: 'Popular', rating: movie.vote_average };
        });

        // Log the popularMovies array after it's been populated
        console.log('Popular Movies:', this.popularMovies);
      });
    })
    .catch(error => {
      console.error('Error fetching popular movies:', error);
    });
},

navigateToLogin() {
      // Navigate to the login page using your router
      this.$router.push('/login'); // Replace '/login' with the actual route path for the login page
    },
    fetchTVShows() {
  axios.get(`https://api.themoviedb.org/3/tv/popular?api_key=2254a7974190a9cae8d203fdeb00fd0b&language=en-US&page=1`)
    .then(response => {
      const tvShowPromises = response.data.results.map(tvShow => {
        return axios.get(`https://api.themoviedb.org/3/tv/${tvShow.id}/external_ids?api_key=2254a7974190a9cae8d203fdeb00fd0b`);
      });

      Promise.all(tvShowPromises).then(externalIdsResponses => {
        this.tvShows = response.data.results.map((tvShow, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id || 'N/A';
          return { ...tvShow, imdb_id: imdbId, category: 'TV Show' };
        });
        console.log('TV Shows:', this.tvShows);
      });
    })
    .catch(error => {
      console.error('Error fetching TV shows:', error);
    });
},

  fetchThrillerMovies() {
    axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=2254a7974190a9cae8d203fdeb00fd0bc40&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${Math.floor(Math.random() * 100) + 1}&vote_count.gte=100&with_genres=53`)
      .then(response => {
        const moviePromises = response.data.results.map(movie => {
          return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=2254a7974190a9cae8d203fdeb00fd0b`);
        });
        Promise.all(moviePromises).then(externalIdsResponses => {
          this.thrillerMovies = response.data.results.map((movie, index) => {
            const imdbId = externalIdsResponses[index].data.imdb_id;
            // Update movie object to include category and rating
            return { ...movie, imdb_id: imdbId, category: 'Thriller', rating: 'Rating' };
          });

          // Log the thrillerMovies array after it's been populated
          console.log('Thriller Movies:', this.thrillerMovies);
        });
      })
      .catch(error => {
        console.error('Error fetching thriller movies:', error);
      });
  },
  searchMovies() {
  const apiKey = 'a6a07bdb1ae12672ae2d301063d83c40';
  const movieUrl = `https://api.themoviedb.org/3/search/movie?api_key=${apiKey}&query=${encodeURIComponent(this.searchQuery)}`;

  axios.get(movieUrl)
    .then(response => {
      this.searchResults = response.data.results.map(movie => ({
        title: movie.title,
        img: `https://image.tmdb.org/t/p/w500${movie.poster_path}`,
        year: movie.release_date.split('-')[0],
        imdb_id: movie.id,
        type: 'Movie'
      }));
    }).catch(error => {
      console.error('Error searching movies:', error);
    });
}
,
  showMovieDialog(movieId) {
    // Additional method to fetch movie details or directly open the player dialog
    this.moviePlayerUrl = `https://vidsrc.pro/embed/movie/${movieId}`;
    this.dialog = true;
  },
    fetchComedyMovies() {
    axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=2254a7974190a9cae8d203fdeb00fd0b&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${Math.floor(Math.random() * 100) + 1}&vote_count.gte=100&with_genres=35`)
      .then(response => {
        const moviePromises = response.data.results.map(movie => {
          return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=2254a7974190a9cae8d203fdeb00fd0b`);
        });

        Promise.all(moviePromises).then(externalIdsResponses => {
          this.comedyMovies = response.data.results.map((movie, index) => {
            const imdbId = externalIdsResponses[index].data.imdb_id;
            // Update movie object to include category and rating
            return { ...movie, imdb_id: imdbId, category: 'Comedy', rating: 'Rating' };
          });

          // Log the comedyMovies array after it's been populated
          console.log('Comedy Movies:', this.comedyMovies); 
        });
      })
      .catch(error => {
        console.error('Error fetching comedy movies:', error);
      });
  },
  redirectToAllMovies() {
      this.$router.push('/allmovies');
    },
    fetchRandomMovies() {
  axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=false&page=${Math.floor(Math.random() * 100) + 1}&vote_count.gte=100`)
    .then(response => {
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
      });

      Promise.all(moviePromises).then(externalIdsResponses => {
        this.randomMovies = response.data.results.map((movie, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id;
          // Update movie object to include category and rating
          return { ...movie, imdb_id: imdbId, category: 'Category', rating: 'Rating' };
        });

        // Log the randomMovies array after it's been populated
        console.log('Random Movies:', this.randomMovies);
      });
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
},
searchMovie() {
  if (!this.search) return;

  // Prepare API URLs for both movie and TV series searches
  const movieUrl = `https://api.themoviedb.org/3/search/movie?api_key=a6a07bdb1ae12672ae2d301063d83c40&query=${encodeURIComponent(this.search)}`;
  const tvUrl = `https://api.themoviedb.org/3/search/tv?api_key=a6a07bdb1ae12672ae2d301063d83c40&query=${encodeURIComponent(this.search)}`;

  // Execute both search queries in parallel using axios.all
  axios.all([
    axios.get(movieUrl),
    axios.get(tvUrl)
  ])
  .then(axios.spread((movieResponse, tvResponse) => {
    // Combine movie and TV search results
    this.searchResults = [...movieResponse.data.results, ...tvResponse.data.results];

    // Check if any results are available
    if (this.searchResults.length) {
      // Assuming you want to show the first result, check its type and use the appropriate player
      const firstResult = this.searchResults[0];
      const isMovie = firstResult.hasOwnProperty('title'); // Movies have 'title', TV shows have 'name'
      this.showMoviePlayer(firstResult.id, isMovie);
    } else {
      alert('No movies or TV shows found.');
    }
  }))
  .catch(error => {
    console.error('Error searching for movies or TV shows:', error);
  });
},
fetchRecentMovies() {
  axios.get(`https://api.themoviedb.org/3/movie/now_playing?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&page=1`)
    .then(response => {
      const moviePromises = response.data.results.map(movie => {
        return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`);
      });

      Promise.all(moviePromises).then(externalIdsResponses => {
        this.recentMovies = response.data.results.map((movie, index) => {
          const imdbId = externalIdsResponses[index].data.imdb_id;
          return { ...movie, imdb_id: imdbId };
        });
        console.log('Recent Movies:', this.recentMovies);
      });
    })
    .catch(error => {
      console.error('Error fetching recent movies:', error);
    });
},

    playMovie(imdbId) {
      this.showMoviePlayer(imdbId);
    },
    fetchMovies() {
  axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&page=1`)
    .then(response => {
      const moviePromises = response.data.results.map(movie => {
        return Promise.all([
          axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/external_ids?api_key=a6a07bdb1ae12672ae2d301063d83c40`),
          axios.get(`https://api.themoviedb.org/3/movie/${movie.id}/videos?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`)
        ]).then(([idsResponse, videosResponse]) => {
          const trailer = videosResponse.data.results.find(video => video.type === 'Trailer' && video.site === 'YouTube');
          return {
            ...movie,
            imdb_id: idsResponse.data.imdb_id,
            trailerUrl: trailer ? `https://www.youtube.com/embed/${trailer.key}?autoplay=1&mute=1&controls=0&fs=0&loop=1&playlist=${trailer.key}` : null // Loop added for continuous play
          };
        });
      });

      Promise.all(moviePromises).then(movies => {
        this.movies = movies;
      });
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
},


    redirectToPage() {
      this.$router.push('/about');
    },
    redirectMarvel() {
      this.$router.push('/marvel');
    },
    getImageUrl(path) {
  return `https://image.tmdb.org/t/p/original${path}`;
}
,
    logImdbId(imdbId) {
      console.log('Clicked movie IMDb ID:', imdbId);
    },
    showMoviePlayer(imdbId) {
      this.logImdbId(imdbId);
      this.moviePlayerUrl = ` https://vidsrc.pro/embed/movie/${imdbId}`;
      this.dialog = true;
    },
    updateContinueWatching(movieId, progress) {
      let watching = JSON.parse(localStorage.getItem('continueWatching')) || [];
      let index = watching.findIndex(w => w.imdb_id === movieId);
      
      if (index !== -1) {
        watching[index].progress = progress;
      } else {
        watching.push({ imdb_id: movieId, progress });
      }

      localStorage.setItem('continueWatching', JSON.stringify(watching));
    },

    loadContinueWatching() {
      this.continueWatching = JSON.parse(localStorage.getItem('continueWatching')) || [];
    },

    showMoviePlayer(imdbId) {
      // Call this when you start playing a movie
      this.logImdbId(imdbId);
      this.updateContinueWatching(imdbId, 0); // Assuming starting from 0% progress
      this.moviePlayerUrl = `https://vidsrc.pro/embed/movie/${imdbId}`;
      this.dialog = true;
    },
  }
}
</script>

<style scoped>
.carousel-item::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.2) 75%, rgba(0,0,0,0) 100%);
}
.card {
    background: radial-gradient(circle at top, #ff0000, #000000);
    height: 240px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.5);
    transition: transform 0.3s ease, background 0.3s ease;
    position: relative;
    overflow: hidden;
  }

  .card img {
    transition: transform 0.3s ease;
    max-height: 100px;
    max-width: 90%;
  }

  .card:hover {
    transform: scale(1.05);
  }

  .card:hover img {
    transform: scale(1.2);
  }

  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0); /* No overlay initially */
    transition: background 0.3s ease;
    border-radius: 20px; /* Match the border radius of the card */
  }

  .card:hover::before {
    background: rgba(0, 0, 0, 0.5); /* Dark overlay on hover */
  }
  .disney:hover {
    background: url('https://wallpapers.com/images/high/disney-computer-wallpaper-r7tmydo1x032cwym.webp') center/cover no-repeat;
  }

  .netflix:hover {
    background: url('https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTEyL3Jhd3BpeGVsX29mZmljZV80Nl9ibGFja193YWxscGFwZXJfbmVvbl9saWdodF9taW5pbWFsX3NpbXBsZV9iZmIzZTBiNi0xNjNjLTRhZDYtOWE1Ny01MjNjNjU4ODY4MmRfMS5qcGc.jpg') center/cover no-repeat;
  }

  .marvel:hover {
    background: url('https://wallpapers.com/images/featured/marvel-aesthetic-laptop-0goyjc3omk6gyd17.webp') center/cover no-repeat;
  }

  .dc:hover {
    background: url('https://wallpapers.com/images/high/dc-superheroes-in-arcade-2pgn6nu6ahyjxick.webp') center/cover no-repeat;
  }
.movie-title {
  font-size: 50px;
  font-weight: bold;
}

.movie-description {
  font-size: 1.1em;
  margin-top: 0.5em;
  max-width: 45%;
}

.overlay-text {
  position: absolute;
  bottom: 20px;
  left: 40px;
  padding: 0; /* Ensuring no padding */
}

.v-carousel-item,
.v-carousel {
  background-size: cover !important;
  background-position: center center !important;
}


.white--text {
  color: white !important;
}

.movie-info {
  font-size: 1em;
  margin-top: 0.5em;
}
/* Add this CSS to your stylesheet or style block */
.v-toolbar__title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust the height as needed */
}

.scrolling-wrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
@media (max-width: 600px) {  /* Adjust the max-width as needed to target your specific mobile breakpoint */
  .responsive-row {
    display: none;
  }
}

.scrolling-wrapper {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}
@media (max-width: 600px) {
  .movie-description {
    font-size: 10px; /* Smaller font size for mobile screens */
  }
}
.v-carousel-item {
  position: relative;
  overflow: hidden;
}

.v-carousel-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.v-col {
  display: inline-block;
  float: none;
}
.movie-card:hover {
  transform: scale(1.05);
}

.movie-image {
  border-radius: 20px 20px 0 0;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.movie-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  border-radius: 0 0 20px 20px;
}

.movie-card:hover .movie-info-overlay {
  opacity: 1;
}

.movie-title {
  font-size: 16px;
  font-weight: bold;
}

.movie-rating {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.movie-card:hover .movie-image {
  transform: scale(1.1);
}
</style>
